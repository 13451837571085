import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Nav, NavDropdown, Row, Col } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Menu = () => {
    return (
        
            <Container style={{
                backgroundColor: 'white',
                maxWidth: '100%',
                marginTop: '20px'
            }}>
                <Row>
                    <Col sm="auto" className='vertical-Center' style={{textAlign:"center"}}>
                        <Link to="/">
                            <StaticImage
                                src='../images/logo.jpg'
                                width={200}
                                quality={60}
                                formats={["auto", "webp", "avif"]}
                                alt="Spielgruppe Wakiti Logo"
                                style={{ marginBottom: `1.45rem` }}
                            />
                        </Link>
                    </Col>
                    <Col className={'horizontal-abs-center vertical-center'}>
                        <Navbar expand="lg" className='navbar-custom'>
                            <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav" >
                            <Nav className="me-auto" style={{textAlign: "center"}}>
                                <Nav.Item><Link to='/' className='nav-link'>Startseite</Link></Nav.Item>
                                <NavDropdown title="Über uns" id="basic-nav-dropdown" >
                                    <Nav.Item><Link to='/team' className='dropdown-item'>Team</Link></Nav.Item>
                                    <Nav.Item><Link to='/lage' className='dropdown-item'>Lage</Link></Nav.Item>
                                    <Nav.Item><Link to='/galerie' className='dropdown-item'>Galerie</Link></Nav.Item>
                                </NavDropdown>
                                <NavDropdown title="Angebot" id="basic-nav-dropdown">
                                    <Nav.Item><Link to='/konzept' className='dropdown-item'>Konzept</Link></Nav.Item>
                                    <Nav.Item><Link to='/rahmenbedingungen' className='dropdown-item'>Rahmenbedingungen</Link></Nav.Item>
                                </NavDropdown>
                                <Nav.Item><Link to='/anmeldungundkontakt' className='nav-link'>Anmeldung & Kontakt</Link></Nav.Item>

                            </Nav>
                            </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        
    )
}

  export default Menu;