import * as React from "react"
import { Link } from "gatsby";

const Footer = () => (
  <footer className="footer"
    style={{
      background: '#F3F3F3'
    }}
  >
 
 <div className="FooterKontakt">
  <div >
      Naturspielgruppe Wakiti, Sulzbacherstrasse 81, 8610 Uster <br/>
      {/* Telefon: <a style={{color: '#a0a0a0', width: "100%"}} id='tel' href='tel:0041794724244'>079 472 42 44</a> */}
      Telefon: 079 472 42 44 
      &nbsp; {/* leerzeichen */}
      <a style={{color: '#a0a0a0', width: "100%"}} id='email' href='mailto:info@spielgruppe-wakiti.ch'>info@spielgruppe-wakiti.ch</a>
  </div>
  <div className="FooterContent">
    <Link style={{color:"#9DBAAC"}} to='/impressum' >Impressum</Link> | <Link style={{color:"#9DBAAC"}} to='/datenschutz' >Datenschutz</Link>
  </div>
  </div>
  </footer>
)

export default Footer
