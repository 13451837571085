import * as React from "react"
import PropTypes from "prop-types"
import Menu from "./menu"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#94CBDF`,
      // marginBottom: `1.45rem`,
    }}
  >
 
    <Menu/>
    
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
